.oauth-buttons {
    .btn {
        padding: 0.5rem 0.7rem;
    }

    .btn:before {
        content: '';
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
        padding-right: 2.3em;
    }

    .btn--shoptet {
        background-color: white;
        color: #A7C721;
        border: 2px solid #A7C721;
        font-weight: bold;

        &:hover {
            background-color: #A7C721;
            color: white;
        }

        &:before {
            background-image: url("../images/auth/shoptet.png");
        }
    }

    .btn--google_oauth2 {
        background-color: white;
        color: #4285F4;
        border: 2px solid #4285F4;

        &:hover {
            background-color: #4285F4;
            color: white;
        }

        &:before {
            background-image: url("../images/auth/google.png");
        }
    }
    .btn--seznam_cz {
        background-color: white;
        color: #CC0000;
        border: 2px solid #4285F4;

        &:hover {
            background-color: #CC0000;
            color: white;
            &:before {
                background-image: url("../images/auth/seznam_white.svg");
            }
        }

        &:before {
            background-image: url("../images/auth/seznam.svg");
        }
    }
}
