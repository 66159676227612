@import "~@tabler/core";
@import "~@tabler/icons-webfont/dist/tabler-icons.css";
@import "tom-select/src/scss/tom-select.bootstrap5";

@import "application/auth";

.page-item.active .page-link {
  &:hover {
    // bugfix for tabler bug remove after upgrade to beta12+
    background: $pagination-active-bg;
  }
}

@keyframes appear-then-fade {
  0%,
  100% {
    opacity: 0;
  }
  5%,
  60% {
    opacity: 1;
  }
}

.alert.auto-remove {
  animation: appear-then-fade 4s both;
}

.chat-bubble-copilot {
  background-color: var(--#{$prefix}azure);
  box-shadow: none;
}
.chat-bubble-event {
  background-color: var(--#{$prefix}secondary-bg);
  box-shadow: none;
}

.avatar.copilot {
  background-color: #fbfdbc;
}
